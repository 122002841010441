<template>
    <div class="admin-container">
     <div class="heading">
        <h4>{{sabhadetail.sb_name_en}}</h4>
        <span>Online Payments {{ this.objct.idate }} </span>
     </div>
        <div class="form-group">
        <div class="row">
            <div class="col-sm-4">
                    
                    <input type="date" name="idate"  id="idate" class="form-control" 
                        v-model="objct.idate" >
                    </div>
                    <div class="col-sm-4">
                    <input type="button" @click="getallPayments()" value="Generate" class="btn" id ="gen" />
                </div>
                </div>
            </div>
   
     <table class="ttable" >
                <thead>
                    <tr>
                        <th>Index</th>
                        <th>Customer NIC</th>
                        <th>Name</th>
                        <th>Contact Number</th>
                        <th>Invoice Number</th>
                        <th>Pay Type</th>
                        <th>Amount</th>
                        <th>Description</th>
                        <th>Date Time</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for=" (p,index) in paginatedData" :key="index">

                        <td>{{ (currentPage - 1) * 20 + index + 1 }}</td>
                        <td>{{ p.online_cus_nic  }}</td>
                        <td>{{ p.cus_name }}</td>
                        <td>{{ p.cus_contact }}</td>
                        <td>{{p.online_invoice_num  }}</td>
                        <td>{{ p.pay_type }}</td>
                        <td>{{ p.online_pay_amount  }}</td>
                        <td>{{ p.online_pay_des }}</td>
                        <td>{{ p.online_pay_datetime }}</td>
                    </tr>
                </tbody>
            </table>
            <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
        </div>
  </template>
  <script>
  import axios from "axios";
  import { mapState, mapMutations } from "vuex";
  export default {

name: "OnlinePay",

data() {
    return {
        allPayments: [],
        sabhadetail:[],
        objct:{ idate:""},
        itemsPerPage: 10,
            currentPage: 1,
        // cusData:[]
    }
},
computed: {
        ...mapState(["user"]),
        paginatedData() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    
    // Check if this.data is an array before using slice method
    if (Array.isArray(this.allPayments)) {
      return this.allPayments.slice(startIndex, endIndex);
    } else {
      // Return empty array or handle loading state
      return [];
    }
  },
  totalPages() {
      return Math.ceil(this.allPayments.length / this.itemsPerPage);
    },
    },
   

// created() {
  
//     this.getallPayments()
// },

methods: {
    ...mapMutations(["setUser"]),
    async getallPayments() {
    
         this.allPayments= (await axios.get('/onlinepay/'+this.user.emp_prs_code+"/"+this.objct.idate)).data;
        //  this.sabhadetail= (await axios.get('/pra_sabha/' +this.user.emp_prs_code)).data
        //customersdata
        // this.cusData= (await axios.get('/customersdata/'+this.allPayments.online_cus_nic)).data;
    },
    nextPage(event) {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
        }
        // Prevent default form submission behavior
        event.preventDefault();
        },
        prevPage(event) {
        if (this.currentPage > 1) {
            this.currentPage--;
        }
        // Prevent default form submission behavior
        event.preventDefault();
        },

}
}
 
</script>
<style scoped>

.ttable {
     
     width: 100%;
     border-width:2px;
     border-color : #400f3c;
     /* border-style: solid; */
     font-size: 1.2rem;
     /* background-color :white; */
     /* padding-left: 3rem; */
      /* height: 3rem; */
 }
 th  {
     text-align: center;
}

.ttable, th, td {
border: 1px solid;
}

 
 .table-responsive {
     margin-top: 8vh;
     height: 500px;
 }
.admin-container {
    background-color: #fff;
    /* height: 100vh; */
    padding: 2rem 9%;
    font-size: 16px;
     min-height: 72.3vh;
}
/* #4e0707; */
.admin-container span {
    color: #4e0707;
    font-size: larger;
}
.project-list>tbody>tr>td {
    padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
    width: 22px;
    border: 1px solid #CCC;
}

.table-responsive {
    margin-top: 8vh;
}

.action-btn,
.cancel-btn,
.paid-btn {
    width: 100px;
    height: 25px;
    color: white;
    text-transform: capitalize;
}

.action-btn {
    background-color: #0da9ef;
    margin-right: 10px;
}

.cancel-btn,
.paid-btn {
    background-color: red;
}

.action-btn:hover {
    background-color: #27ae60;
}
</style>