<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <div class="register-container" >
        <h2>welcome! {{ user.emp_name }} </h2>
        <div class="register-form-container">
         
            <form id="userRegisterForm" @submit="handleSubmit" novalidate autocomplete="off">
                <div class="heading">
                 <span>Sabha Admin Register</span>
                 <h4><b>නව නිලධාරීන්</b></h4>
                </div>
                <div class="row">
                <div class="form-group col-md-4">
                    <label for="uLevel">Local Government:
                        <!-- {{ this.user.emp_pro_code  }} -->
                    </label>
                    <div><select v-model="registerObj.prov" id="iHead" class="form-control" >
                            <option value="" selected disabled>
                                 Choose
                             </option>
                            <option v-for="p in provinces" :key="p.sb_code " id="id"  
                            v-bind:value =p.sb_code   >
                               <div>{{ p.sb_name_en }}</div> 
                            </option>
                        </select>
                     </div> 
                    <p class="error-mess" v-if="errorObj.proErr.length > 0">{{ errorObj.proErr[0] }}</p>
                </div>
                <div class="form-group col-md-4">
                    <label for="uPhone">User NIC:
                    </label>
                    <input type="nic" name="uNic" placeholder="enter NIC" id="uNic"
                        class="form-control" v-model="registerObj.nic" />
                    <p class="error-mess" v-if="errorObj.nicErr.length > 0">{{ errorObj.nicErr[0] }}</p>
                </div>

                <div class="form-group col-md-4">
                    <label for="uName">User name:
                    </label>
                    <input type="text" name="uName" placeholder="enter full name" id="uName" class="form-control"
                        v-model="registerObj.name" />
                    <p class="error-mess" v-if="errorObj.nameErr.length > 0">{{ errorObj.nameErr[0] }}</p>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-4">
                    <label for="uPhone">User phone number:
                    </label>
                    <input type="tel" name="uPhone" placeholder="enter user phone number" id="uPhone"
                        class="form-control" v-model="registerObj.phone" />
                    <p class="error-mess" v-if="errorObj.phoneErr.length > 0">{{ errorObj.phoneErr[0] }}</p>
                </div>
               

                <div class="form-group col-md-4">
                    <label for="uAddress">User Address:
                    </label>
                    <input type="text" name="uAddress" placeholder="enter Address" id="uAddress"
                        class="form-control" v-model="registerObj.address" />
                    <p class="error-mess" v-if="errorObj.addressErr.length > 0">{{ errorObj.addressErr[0] }}</p>
                </div>

                <div class="form-group col-md-4">
                    <label for="uEmail">User email:
                    </label>
                    <input type="email" name="uEmail" placeholder="example@gmail.com" id="uEmail" class="form-control"
                        v-model="registerObj.email" />
                    <p class="error-mess" v-if="errorObj.emailErr.length > 0">{{ errorObj.emailErr[0] }}</p>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-4">
                    <label for="ulevel">User Level:
                    </label>
                    <select name="ulevel"  id="ulevel" class="form-control" v-model="registerObj.level">
                        <option value="0" disabled selected> Select User Level</option>
                        <option value="4">Secretary/Accountant</option>
                        <option value="5">System Admin</option>
                    </select>
                   
                    <p class="error-mess" v-if="errorObj.levelErr.length > 0">{{ errorObj.levelErr[0] }}</p>
                </div>
                <div class="form-group col-md-4">
                    <label for="uPass">User password:
                    </label>
                    <input type="password" name="uPass" placeholder="enter user password" id="uPass"
                        class="form-control" v-model="registerObj.pass" />
                    <p class="error-mess" v-if="errorObj.passErr.length > 0">{{ errorObj.passErr[0] }}</p>
                </div>

                <div class="form-group col-md-4">
                    <label for="uPassConfirm">Enter password again:
                    </label>
                    <input type="password" name="uPassConfirm" placeholder="enter password again" id="uPassConfirm"
                        class="form-control" v-model="registerObj.confirm" />
                    <p class="error-mess" v-if="errorObj.confirmErr.length > 0">{{ errorObj.confirmErr[0] }}</p>
                </div>
            </div>
                <!-- <div class="form-group">
                    <label for="uStatus">Status:
                    </label>
                    <input type="number" name="uStatus" placeholder="example only" id="uStatus" class="form-control"
                        v-model="registerObj.status" />
                    <p class="error-mess" v-if="errorObj.statusErr.length > 0">{{ errorObj.statusErr[0] }}</p>
                </div> -->


                <div class="form-group">
                    <input type="submit" value="Register" class="btn" />
                  
                </div>
                <div>
                  <!-- <p>Inactive employees shown as: <router-link to="" class="fas fa-lock"> </router-link> &nbsp; Active employees shown as: <router-link to="" class="fas fa-lock-open"> </router-link></p> -->
                  <!-- <table class="ttable" >
              <thead>
                  <tr>
                      <th>Index</th>
                      <th>NIC</th>
                      <th>Name</th>
                      <th>Sabha</th>
                      <th>Contact</th>
                      <th>Address</th>
                      <th>email</th>
                      <th>Inactive</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for=" (b,index) in paginatedData" :key="index">

                        <td>{{ (currentPage - 1) * 10 + index + 1 }}</td>
                      <td>{{ b.emp_nic }}</td>
                      <td>{{ b.emp_name }}</td>
                      <td>{{ b.sb_name_en }}</td>
                      <td>{{ b.emp_contact }}</td>
                      <td>{{ b.emp_address }}</td>
                      <td>{{ b.emp_email }}</td>
                      <td v-if="b.emp_status==0"><router-link to="" @click="inactiveAdmin(b.emp_nic,b.emp_status)"  class="fas fa-lock"> </router-link></td>
                      <td v-else><router-link to="" @click="inactiveAdmin(b.emp_nic,b.emp_status)"   class="fas fa-lock-open"> </router-link></td>
                      
                      // <td><router-link to="" @click="inactiveAdmin(b.emp_nic)"  class="fas fa-trash-alt"> </router-link></td> 
                  </tr>
              </tbody>
          </table> -->
          <!-- <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button> -->
              </div>
            </form>
            <!-- table new -->
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapMutations } from "vuex";
import { mapState } from "vuex";
import VueBasicAlert from 'vue-basic-alert'
export default {
    name: "RegisterSadmin",

    data() {
        return {
            registerObj: { prov:"", nic:"", name: "", phone: "", address:"",  email: "", PrsCode:"", pass: "", confirm: "", status:"",level:0  },
            errorObj: {  proErr:[], nicErr:[], nameErr: [], phoneErr: [], addressErr:[],  emailErr: [], PrsCodeErr:[], passErr: [], confirmErr: [], statusErr:[0],levelErr: [] },
            matchUser: undefined,
            provinces:[],
            allEmployee:[],
            itemsPerPage: 10,
            currentPage: 1,
        }
    },
    created() {
        
        this.getProvinces()
      
    },
    computed:{
        ...mapState(["user"]),
    paginatedData() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    
    // Check if this.data is an array before using slice method
    if (Array.isArray(this.allEmployee)) {
      return this.allEmployee.slice(startIndex, endIndex);
    } else {
      // Return empty array or handle loading state
      return [];
    }
  },
  totalPages() {
      return Math.ceil(this.allEmployee.length / this.itemsPerPage);
    },
    },

    methods: {
        ...mapMutations(["setUser"]),

        async getMatchUser(nic) {
            await axios.get('/employee/' + nic);
            // let data = await axios.get('/employee/' + nic);
            //this.matchUser = data.data;
        },
        addZero(num) {

num = num.toString();
    return '19' + num.slice(0, 5) + '0' + num.slice(5, -1);
},
addZeroPadding: function() {
// this.len= this.loginObj.nic.charAt(9).toUpperCase()
// Check if the user input is a valid number
if (!isNaN(parseInt(this.registerObj.nic)) && this.registerObj.nic !== null && (this.registerObj.nic.charAt(9).toUpperCase() === 'V' || this.registerObj.nic.charAt(9).toUpperCase() === 'X')) {

// if (!isNaN(parseInt(this.registerObj.nic)) && this.registerObj.nic !== null && this.registerObj.nic.charAt(9).toUpperCase() === 'V') {
    
    this.paddedNum = this.addZero(this.registerObj.nic);

}else{
    this.paddedNum=parseInt(this.registerObj.nic);
}
return this.paddedNum
},
nextPage(event) {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
        }
        // Prevent default form submission behavior
        event.preventDefault();
        },
        prevPage(event) {
        if (this.currentPage > 1) {
            this.currentPage--;
        }
        // Prevent default form submission behavior
        event.preventDefault();
        },
        async getProvinces() {

            this.provinces = (await axios.get('/provofsabha/' +this.user.emp_pro_code)).data;
            this.allEmployee= (await axios.get('/adminbyprov/'+this.user.emp_pro_code+"/"+this.user.user_level)).data;  
        },
        async inactiveAdmin(empnic,upst){
  let result =''
  this.getMatchUser(empnic)
  if(this.matchUser.emp_status==1){
      result = confirm("Confirm Inactivating employee?")
  }
  else{
      this.$refs.alert.showAlert('error', 'Not Permission to activate !')
  }    
      if(result == true){
    
      if(upst == 1){
       
          await axios.put("/inactiveemp/"+empnic,'0' );
          this.$refs.alert.showAlert('success', 'Inactivated !')
      }
      // else{
     
      //     await axios.put("/inactiveemp/"+empnic,'1');
      //     this.$refs.alert.showAlert('success', 'activated !'+upst)
      // }
         
      this.allEmployee= (await axios.get('/adminbyprov/'+this.user.emp_pro_code+"/"+this.user.user_level)).data;  
         
      }
  },

        scrollToTop() {
            window.scrollTo(0, 0);
        },


        resetCheckErr: function () {
            this.errorObj.proErr = [];
            this.errorObj.nicErr = [];
            this.errorObj.nameErr = [];
            this.errorObj.phoneErr = [];
            this.errorObj.addressErr =[];
            this.errorObj.emailErr = [];
            this.errorObj.PrsCodeErr =[];
            this.errorObj.passErr = [];
            this.errorObj.confirmErr = [];
            this.errorObj.statusErr = [];
           
        },

        checkEmptyErr: function () {
            for (var typeErr in this.errorObj) {
                if (this.errorObj[typeErr].length != 0) {
                    return false;
                }
            }
            return true;
        },

        checkForm: function () {
            this.resetCheckErr();

            // Province validate
            if (!this.registerObj.prov) {
                this.errorObj.proErr.push("Province is required");
            }
            // Name validate
            if (!this.registerObj.name) {
                this.errorObj.nameErr.push("Entering a name is required");
            }
            else {
                if (!/^[A-Za-z]+$/.test(this.registerObj.name.replace(/\s/g, ""))) {
                    this.errorObj.nameErr.push('A name can only contain letters');
                }
            }
            // NIC validate

            if (!this.registerObj.nic) {
                this.errorObj.nicErr.push('Entering NIC number is required');
                }else {
                    // if (this.registerObj.nic.length !==12)
                    // {
                    //     if((this.registerObj.nic.charAt(9).toUpperCase() !== 'V') || (this.registerObj.nic.charAt(9).toLowerCase() !== 'v'))
                    //       {
                    //           this.errorObj.nicErr.push('Please Enter Correct Nic Number');
                    //       }
                          
                    // }
                    if ( this.registerObj.nic.length !== 12 &&
                            !(
                                this.registerObj.nic.charAt(9).toUpperCase() === 'V' ||
                                this.registerObj.nic.charAt(9).toLowerCase() === 'v' ||
                                this.registerObj.nic.charAt(9).toUpperCase() === 'X' ||
                                this.registerObj.nic.charAt(9).toLowerCase() === 'x'
                            )
                        ) {
                            this.errorObj.nicErr.push('Please Enter Correct Nic Number');
                        }
            }

            // Email validate
            if (!this.registerObj.email) {
                this.errorObj.emailErr.push("Entering a email is required");
            }
            else {
                if (!/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/.test(this.registerObj.email)) {
                    this.errorObj.emailErr.push('Email must be valid');
                }
            }

            // Pass validate
            if (!this.registerObj.pass) {
                this.errorObj.passErr.push('Password is required');
            }
            else {
                if (!/[!@#$%^&*]/.test(this.registerObj.pass)) {
                    this.errorObj.passErr.push('Password must contain at least 1 special character');
                }

                if (this.registerObj.pass.length < 8) {
                    this.errorObj.passErr.push('Password must be more than or equal 8 characters');
                }
            }

            // Confirm Pass validate
            if (!this.registerObj.confirm) {
                this.errorObj.confirmErr.push('Confirm password is required');
            }
            else {
                if (this.registerObj.pass !== this.registerObj.confirm) {
                    this.errorObj.confirmErr.push('Confirm password must be match with password');
                }
            }
            // Phone validate
            if (!this.registerObj.phone) {
                this.errorObj.phoneErr.push('Entering phone number is required');
            } else{
                if(!/[0-9]{10}/.test(this.registerObj.phone)) {
                this.errorObj.phoneErr.push('Phone numbers should contain 10 Digits, no spaces allowed');
                }
            }

        },
//pro_code 
        async handleSubmit(e) {
            this.checkForm();

            if (!this.checkEmptyErr()) {
                e.preventDefault();
            } else {
                e.preventDefault();
                await this.getMatchUser(this.registerObj.nic);
                if (this.matchUser) {
                    this.errorObj.nicErr.push("NIC already exist")
                }
                else {
                    let Nic12 = this.addZeroPadding();
                    let data = {
                        emp_pro_code: this.user.emp_pro_code,
                        emp_nic: Nic12,
                        emp_name: this.registerObj.name,
                        emp_contact: this.registerObj.phone,
                        emp_address: this.registerObj.address,
                        emp_email: this.registerObj.email,
                        emp_prs_code: this.registerObj.prov,
                        emp_password: this.registerObj.pass,
                        user_level:this.registerObj.level,
                        status:'1',
                    }
                    await axios.post("/employee/", data);
                    this.$refs.alert.showAlert('success', 'Successfully Registered !')
                    document.getElementById("userRegisterForm").reset();
                    this.allEmployee= (await axios.get('/adminbyprov/'+this.user.emp_pro_code+"/"+this.user.user_level)).data; 
                    this.registerObj.prov=""
                    this.registerObj.nic=""
                    this.registerObj.name=""
                    this.registerObj.phone=""
                    this.registerObj.address=""
                    this.registerObj.email=""
                    this.registerObj.pass=""
                    this.registerObj.confirm=""

                }
            }
        }
    },
    components: {
        VueBasicAlert
    }

};
</script>


<style scoped>
.ttable {
   
   width: 100%;
   border-width:1px;
   border-color : #130f40;
   /* border-style: solid; */
   font-size: 1.5rem;
   /* background-color :white; */
   /* padding-left: 3rem; */
    /* height: 3rem; */
    border-collapse: collapse;
}
th  {
   text-align: center;
}
.ttable, th, td {
border: 1px solid;
text-align: center;
}

.register-container {
    padding: 2rem 9%;
     min-height: 72.3vh;
}

.register-container .register-form-container {
    background: #fff;

}

.register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    /* max-width: 70rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
    padding-bottom: 1rem;
    font-size: 2rem;
    text-transform: uppercase;
    color: #130f40;
    margin: 0;
}

.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.6rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    /* border: none; */
    border: margin-bottom; 
}

.register-container .register-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.register-container .register-form-container form span {
    font-size: 18px;
    padding-left: 5px;
    padding-right: 40px;
    color:#620a0a;
}

.register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 25%;
    text-align: center;
    background: #620a0a;
}

.register-container .register-form-container form p {
    padding-top: 1rem;
    font-size: 1.5rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group {
    margin: 0;
}

.register-container .register-form-container form .form-group .error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
}
</style>
