<template>
        <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <div class="register-container">
        <!-- <router-link to="/subsummery" class="btn" style="background-color:  #d30c0c">Income</router-link>&nbsp;
        <router-link to="/subsummon" class="btn" style="background-color:#0000ff " @click="getDataFromSessionStorage()">Expenses </router-link>&nbsp;
        <br> <br> -->
        <div class="register-form-container">
            <form id="SubjectSummaryForm" @submit="handleSubmit" novalidate autocomplete="off" >
    <div class="form-group">
        <h1 style="text-align: center;">
               Estimate
        </h1>
        <div class="row">
           
                <div class="col-sm-4">
                    <label for="iStamp">Year
                    </label>
                    <select id="year" v-model="selectedYear" class="form-control">
                        <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                    </select>
                </div>
                
                <br>
            </div>
        </div>
    
               <br>
        <div id="PrintContent">
            <div v-if="topic" class="heading">
            </div>
               <div class="row">
               <table class="ttable" >
               <thead>
                   <tr>   
                       <th style="width: 5%;">Index</th>
                       <th>Income head</th>
                       <th>Income head Name</th>
                       <th style="width: 25%;">Estimated Income (Rs.)</th>
                         
                   </tr>
               </thead>
               <tbody>
                   <tr v-for=" (r,index) in dailyreport" :key="index">
                        <td>{{ index+1 }}</td>
                       <td style="text-align:left;"> &nbsp;&nbsp;{{ r.sb_rate_head }}</td>
                       <td style="text-align:left;">{{ r.sb_rate_head_name }}</td>
                       <td><input type="text" name="eincome" class="form-control" v-model="estdata[index]" required></td>

                   </tr>
               </tbody>
           </table>
        </div>
           </div>   
            </form>
            <div>
                <h5> <input @click="btnenable"  type="checkbox" id="checkid" v-model="checkVal"  >
                Above information cannot be Edited. Hence I certify that the above information are correct.| ඉහත තොරතුරු නැවත යාවත්කාලීන කල නොහැක. එබැවින් මෙම තොරතුරු නිවැරදි බවට සහතික කරමි.</h5>
            </div>
            <div class="form-group1">
                    <input type="button" value="Submit" :disabled="disableButton" class="btn" @click="saveData()"/>   
                </div>
            </div>
            </div>    
</template>
<script>
import axios from 'axios';
import VueBasicAlert from 'vue-basic-alert'
export default {
    name: 'Estimated',

    data() {
       return {
            nic:"",
            sabha: "",
            userLevel:"",
            sabhaIncomeHead : undefined,
            subObj:{headid:"", idate:""},
            allIncomeHead:[],
            dailyreport:[],
            selectedYear: null,
            years: [],
            disableButton:true,
            estdata:[],
       };
    },
    mounted() {
    // Populate the years array with a range of years
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 20; // Adjust this value to set the start year
    const endYear = currentYear + 20; // Adjust this value to set the end year

    for (let year = startYear; year <= endYear; year++) {
      this.years.push(year);
    }

    // Set the default selected year
    this.selectedYear = currentYear;
  },
created() {
       this.getDataFromSessionStorage()
     
   },
   methods: {

       async getDataFromSessionStorage(){
           const data = JSON.parse(sessionStorage.getItem('userData'))
           if(data){
                           this.nic = data.nic 
                           this.sabha = data.sabha
                           this.userLevel =data.userLevel

                       }
           let sabhaCode = this.sabha
           let Sabhadata = await axios.get('/subheads/'+ this.nic +"/"+ sabhaCode)
           this.sabhaIncomeHead = Sabhadata.data


       let reportdata = await axios.get('/allnewvotes/'+sabhaCode);
       this.dailyreport = reportdata.data
       
       },
       btnenable(){
            if(this.checkVal==true){
                this.disableButton = true;
            
            }else{
                this.disableButton = !this.disableButton;
            }
        }, 
       async saveData(){
        for (let i=0;i<=this.dailyreport.length;i++){
            // for (let x of this.estdata){
            const edata ={
                sabha :this.sabha,
                vote :this.dailyreport[i].sb_rate_head,
                year :this.selectedYear,
                estd_income:this.estdata[i]
            }
            await axios.post("/saveest/", edata);
            this.$refs.alert.showAlert('success', 'Added Successfully !')
        }
        // }
       }

    },
    components: {
        VueBasicAlert
    }

    }
</script>

<style scoped>
.btn {
    background-color: #af74a7;   
    width: 15rem;
    height: 2.7rem;
    font-size: small;
    padding-top: 0.3rem;
    /* padding-bottom: 0.5rem; */
}
.ttable {
     
     width: 100%;
     border-width:2px;
     border-color : #130f40;
     /* border-style: solid; */
     font-size: 1.2rem;
     /* background-color :white; */
     /* padding-left: 3rem; */
      /* height: 3rem; */
 }
 th  {
     text-align: center;
}

.ttable, th, td {
border: 1px solid;
}
.form-group1{
    padding-left: 35%;
    padding-top: 2%;
}
.register-container {
    padding: 1.5rem 9%;
    min-height: 75vh;
}

.register-container .register-form-container {
    background: #fff;

}

.register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    /* max-width: 98rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
    padding-bottom: 0.5rem;
    font-size: 1.9rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: center;
    
}

.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
}

.register-container .register-form-container form .form-control1 {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 40%;
    border: none;
    padding-bottom: 0.5rem;
}
.register-container .register-form-container form .form-radio {
    margin: 2rem 0%;
    border-radius: 0.5rem;
    background: #f7f7f7;
   padding-bottom: 1rem;
    font-size: 10rem;
    color: #130f40;

    width: 10%;
    border: none;
    margin-left: 10%;
}
.register-container .register-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.register-container .register-form-container form span {
    font-size: 25px;
    /* padding-left: 5px;
    padding-right: 40px; */
    text-align: center;
    color: #620a0a;

}



.register-container .register-form-container form p {
    padding-top: 0.75rem;
    font-size: 1rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group  {
    margin: 0;
}

.register-container .register-form-container form .form-group  .error-mess {
    font-size: 1rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
    width: 100%;

} 
.register-container .register-form-container form .hide {
    display: none;

} 
</style>