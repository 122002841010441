<template>
    <div class="admin-container">
      
            <!-- <h1>Hello {{user.emp_name}}!</h1> -->
            <!-- <router-link to="" class="btn" style="background-color: crimson;">Income</router-link>&nbsp; -->
            <!-- <router-link to="/mindash" class="btn" style="background-color:maroon ">Home</router-link>&nbsp;
            <router-link to="/detaildsecvw" class="btn" style="background-color:crimson ">Secretaries</router-link>&nbsp;
            <router-link to="/detaildempvw" class="btn" style="background-color:goldenrod;">Employees</router-link> -->
            <div class="row">
            <div class="col-sm-6">
                    <label id="uProvince"><b>Select Province | පළාත තෝරන්න:</b>
                    </label>
                       
                           <select  @change="changeprovince"   v-model="selObj.pro"  name="province" id="province" class="form-control">
                                <option value="" selected disabled>Choose</option>
                                <option v-for="f in province" v-bind:key="f.pro_id" v-bind:value="f.pro_code">{{ f.pro_name }} </option>
                                
                                
                            </select>
                
                    <!-- <p class="error-mess" v-if="errorObj.proErr.length > 0">{{ errorObj.proErr[0] }}</p> -->

                    
                </div>
                <div class="col-sm-3">
                    <label id="uProvince"><b>Select Month | මාසය තෝරන්න:</b>
                    </label>
                    <input type="month" @input="genReport()" name="idate" id="idate" class="form-control" v-model="selObj.selmonth" />
                         
                    <!-- <p class="error-mess" v-if="errorObj.proErr.length > 0">{{ errorObj.proErr[0] }}</p> -->

                    
                </div>
                <div class="col-sm-2">
                    <label id="usearch"><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                    </label>
                    <input type="button" value="Search>>" class=" btn" @click="search">
                    
                </div>
                <div class="col-sm-1">
                    <label id="usearch"><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       </b>
                    </label>
                    <input type="button" value="Print" class=" btn1" @click="print">
                    
                </div>
                </div>
                <div id="Printcontent">
<div class="home-main" >
               
        
        <div  v-if="getYear && selObj.pro" class="content">
            <span > {{ proName }} පළාත </span>
           
           <br>
           <h4>Total Income in {{monthName}} of {{ getYear }} - Rs.{{ totalincome}}</h4>
        </div>
        </div>

        <div  v-if="getYear && selObj.pro" class="row">
        
            <table style="align-items: center; width: 100%;  border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" >
                <thead>
                    <tr style=" border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; background-color:#b2beb5;">
                        <th style=" border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; background-color:#b2beb5;">Index</th>
                        <th style=" border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; background-color:#b2beb5;">Sabha Name</th>
                        <th style=" border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; background-color:#b2beb5;">Sabha Income(Rs.)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for=" (b,index) in sabhaDetails" :key="index">

                        <td style="border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; width: 5%; text-align: center;">{{ (currentPage - 1) * 10 + index + 1 }}</td>
                        <!-- <td style="width: 60%; text-align: left;"><router-link :to="{name:'DetailedVoteView',params:{data1:b.sb_code,data2:b.sb_name_en}}">&nbsp;&nbsp;{{ b.sb_name_en }}</router-link></td> -->
                        <td style="border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; width: 60%; text-align: left;">&nbsp;&nbsp;{{ b.sb_name_en }}</td>
                        <td style="border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; width: 35%; text-align: right;">{{$formatNumber( b.invoice_count) }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                    </tr>
                </tbody>
            </table>
            <!-- <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button> -->
        </div>
  
     
    </div>
</div>
</template>


<script>
import axios from 'axios';
import { mapState, mapMutations } from "vuex";
export default {
    name: 'DetaildViewMonthly',

    
    data() {
        return {
            sabhaDetails:[],
            itemsPerPage: 10,
            currentPage: 1,
            searchprov:[],
            proName:"",
            totalincome:0,
            procode:'',
            selObj:{pro:'',selmonth:''},
            province:[],
            monthName:'',
            getYear:""
            
        }
    },
    created(){
        
       this.getDataFromSessionStorage(),
       this.Allprovince()
    },
    computed: {
        ...mapState(["user"]),
       
//     paginatedData() {
//     const startIndex = (this.currentPage - 1) * this.itemsPerPage;
//     const endIndex = startIndex + this.itemsPerPage;

//     if (Array.isArray(this.sabhaDetails)) {
//       return this.sabhaDetails.slice(startIndex, endIndex);
//     } else {
//       // Return empty array or handle loading state
//       return [];
//     }
//   },
  totalPages() {
      return Math.ceil(this.sabhaDetails.length / this.itemsPerPage);
    },
    },
    methods: {
        ...mapMutations(["setUser"]),
        async Allprovince() {
           
           this.province = (await axios.get('/provinces/' )).data;
          
          },
          async search(){
            const selectedmon =  (this.selObj.selmonth).split("-")
                let selectedMonth=selectedmon[1]
                let selectedYear=selectedmon[0]
                this.monthName=this.getMonthName(selectedMonth)
               this.getYear=selectedYear
                       this.sabhaDetails= (await axios.get('/proincomemonthly/'+this.selObj.pro+'/'+selectedYear+'/'+selectedMonth)).data;
                    
                       let incomedate = (await axios.get('/prototalincomemonthly/'+this.selObj.pro+'/'+selectedYear+'/'+selectedMonth)).data;
                       this.totalincome = incomedate[0].invoice_count;

                       let provincedata = (await axios.get('/provincebyid/'+this.selObj.pro)).data;   
                       this.proName=provincedata[0].pro_name
          },
        async getDataFromSessionStorage(){
           const data = JSON.parse(sessionStorage.getItem('userData'))
           if(data){
                           this.nic = data.nic 
                           this.sabha = data.sabha
                           this.userLevel =data.userLevel
                           this.procode=data.procode
                       }

            // const prodata = {
            //     pr:this.searchprov,
            //     prname:this.proName
            // }
            // window.sessionStorage.setItem('prodata',JSON.stringify(prodata))

       },
       getMonthName:function(month){
         if(month=='01'){
            return 'January';
         }
         else if(month=='02'){
            return 'February';
         }
         else if(month=='03'){
            return 'March';
         } else if(month=='04'){
            return 'Appril';
         } else if(month=='05'){
            return 'May';
         } else if(month=='06'){
            return 'June';
         } else if(month=='07'){
            return 'July';
         } else if(month=='08'){
            return 'Augest';
         } else if(month=='09'){
            return 'September';
         } else if(month=='10'){
            return 'October';
         } else if(month=='11'){
            return 'November';
         } else{
            return 'December';
         }
       },

    // nextPage(event) {
    //     if (this.currentPage < this.totalPages) {
    //         this.currentPage++;
    //     }
    //     // Prevent default form submission behavior
    //     event.preventDefault();
    //     },
    //     prevPage(event) {
    //     if (this.currentPage > 1) {
    //         this.currentPage--;
    //     }
    //     event.preventDefault();
    // }, 
      
        print(){
     
                
                const prtHtml = document.getElementById('Printcontent').innerHTML;
                const WinPrint = window.open('', '', '');
                WinPrint.document.write(`    
            ${prtHtml}
            `);
                WinPrint.focus();
                WinPrint.print();
                WinPrint.close();
               
            }  
                    } 
        }



</script>

<style scoped>
.admin-container {
    background-color: #fff;
    /* height: 100vh; */
    padding: 2rem 9%;
    font-size: 12px;
   min-height: 90vh;
}
.ttable{
    width: 100%;
    font-size: 14px;
    
}
/* .ttable td{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
} */
.project-list>tbody>tr>td {
    padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
    width: 22px;
    border: 1px solid #CCC;
}

.table-responsive {
    margin-top: 8vh;
}

.action-btn,
.cancel-btn,
.paid-btn {
    width: 100px;
    height: 25px;
    color: white;
    text-transform: capitalize;
}

.action-btn {
    background-color: #620a0a;
    margin-right: 10px;
}

.cancel-btn,
.paid-btn {
    background-color: red;
}

.action-btn:hover {
    background-color:  #620a0a;
    
}

/* home.vue's all styles below */
.home-main,
.home-about,
.home-banner,
.home-category {
    padding: 2rem 9%;
    
}

.home-main {
    display: flex;
    align-items: center;
    flex-wrap: wrap-reverse;
    /* gap: 2rem; */
    position: relative;
    overflow: hidden;
    text-align: center;
   
    
}

.home-main .content {
    flex: 1 1 30rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
    /* min-height: 90vh; */
}

.home-main .content span {
    font-size: 1.5rem;
    color: #620a0a;
    padding-top: 0rem;
    padding-bottom: 0rem;
}

.home-main .content h4 {
    font-size: 1.7rem;
    color: #130f40;
    /* padding-top: .5rem; */
}

.home-main .content p {
    font-size: 1.6rem;
    color: #666;
    line-height: 2;
    padding: 1rem 0;
}

.home-main .image {
    flex: 1 1 41rem;
    margin: 2rem 0;
    pointer-events: none;
}

.home-main .image .home-img {
    width: 100%;
    margin-top: 5rem;
}

.home-main .home-parallax-img {
    position: absolute;
    top: -15rem;
    right: -15rem;
    width: 80vw;

}


/* home category */



/* home banner */

.home-banner .row-banner .content {
    position: absolute;
    
    left: 7%;
    transform: translateY(-50%);
}

.home-banner .row-banner .content span {
    font-family: 'Satisfy', cursive;
    font-size: 2rem;
    color: #620a0a;
    color: #130f40;
}

.home-banner .row-banner .content h3 {
    font-size: 6rem;
    color: red;
    text-transform: uppercase;
}

.home-banner .row-banner .content p {
    font-size: 2rem;
    padding-bottom: 1rem;
}


.home-banner .grid-banner .grid {
    border-radius: 1rem;
    overflow: hidden;
    height: 20rem;
    
}

.home-banner .grid-banner .grid:hover img {
    transform: scale(1.2);
}

.home-banner .grid-banner .grid img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.home-banner .grid-banner .grid .content {
    position: absolute;
    top: 2rem;
    padding: 0 2rem;
}


.btn {
    background-color: #d30c0c;   
    width: 15rem;
    height: 2.7rem;
    font-size: small;
    padding-top: 0.3rem;
    /* padding-bottom: 0.5rem; */
}
.btn1 {
    background-color: #eecd12;   
    width: 10rem;
    height: 2.7rem;
    font-size: small;
    padding-top: 0.3rem;
}
.btn2 {
    background-color: #f1f50b;   
    width: 20rem;
}
.home-banner .grid-banner .grid .content.center {
    text-align: center;
    width: 100%;
}

.home-banner .grid-banner .grid .content.center span {
    color: #ca9206;
}

.home-banner .grid-banner .grid .content.center h3 {
    color: #130f40;
}

.home-banner .grid-banner .grid .content span {
    font-size: 1.5rem;
    color: #fff;
}

.home-banner .grid-banner .grid .content h3 {
    font-size: 1rem;
    color: #fff;
    padding-top: .5rem;
}

.home-about {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
    background: #f7f7f7;
}

.home-about .image {
    flex: 1 1 40rem;
}

.home-about .image img {
    width: 100%;
}

.home-about .content {

    flex: 1 1 40rem;
}

.home-about .content span {
    font-family: 'Satisfy', cursive;
    font-size: 3rem;
    color: #620a0a;
}

.home-about .content .title {
    font-size: 3rem;
    padding-top: .5rem;
    color: #130f40;
}

.home-about .content p {
    padding: 1rem 0;
    line-height: 2;
    font-size: 1.4rem;
    color: #666;
}

.home-about .content .icons-container {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.home-about .content .icons-container .icons {
    flex: 1 1 20rem;
    border-radius: .5rem;
    background: #fff;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
}

.home-about .content .icons-container .icons h3 {
    font-size: 1.7rem;
    color: #130f40;
}

@media (max-width: 768px) {
    #menu-btn {
        display: inline-block;
    }

    .home-main .home-parallax-img {
        top: 0;
        right: 0;
        width: 100%;
    }

    .home-banner .grid-banner .content h3 {
        font-size: 15px !important;
    }

    .home-banner .grid-banner .content.center {
        padding-left: 0px !important;
    }

}

@media (max-width: 576px) {
    .home-main .content h3 {
        font-size: 1rem;
    }

    .home-main .content p {
        font-size: 1.5rem;
    }
}
</style>