<template>
    <div class="admin-container">
     <div class="heading">
        <h4>{{sabhadetail.sb_name_en}}</h4>
        <span>Crematoria Booking Service </span>
     </div>
        <div class="form-group">
        <div class="row">
            
                </div>
            </div>
   
     
        </div>
  </template>
  <script>
//   import axios from "axios";
  import { mapState, mapMutations } from "vuex";
  export default {

name: "BkCrematoria",

data() {
    return {
        allPayments: [],
        sabhadetail:[],
        objct:{ idate:""}
    }
},
computed: {
        ...mapState(["user"])
    },
   

// created() {
  
//     this.getallPayments()
// },

methods: {
    ...mapMutations(["setUser"]),
    async getallPayments() {
    
        
    },

}
}
 
</script>
<style scoped>

.ttable {
     
     width: 100%;
     border-width:2px;
     border-color : #400f3c;
     /* border-style: solid; */
     font-size: 1.2rem;
     /* background-color :white; */
     /* padding-left: 3rem; */
      /* height: 3rem; */
 }
 th  {
     text-align: center;
}

.ttable, th, td {
border: 1px solid;
}

 
 .table-responsive {
     margin-top: 8vh;
     height: 500px;
 }
.admin-container {
    background-color: #fff;
    /* height: 100vh; */
    padding: 2rem 9%;
    font-size: 16px;
     min-height: 72.3vh;
}
/* #4e0707; */
.admin-container span {
    color: #4e0707;
    font-size: larger;
}
.project-list>tbody>tr>td {
    padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
    width: 22px;
    border: 1px solid #CCC;
}

.table-responsive {
    margin-top: 8vh;
}

.action-btn,
.cancel-btn,
.paid-btn {
    width: 100px;
    height: 25px;
    color: white;
    text-transform: capitalize;
}

.action-btn {
    background-color: #0da9ef;
    margin-right: 10px;
}

.cancel-btn,
.paid-btn {
    background-color: red;
}

.action-btn:hover {
    background-color: #27ae60;
}
</style>