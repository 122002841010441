<template>
    <div class="admin-container">
     <div class="heading">
        <span>Online Booking Requests </span>
     </div> 
     <div v-for="(innerArray, index) in allRequest" :key="index">
        <p>Booking Request Vote : {{ innerArray[0].vote }}</p>
     <table class="ttable" >
                <thead>
                    <tr>
                        <th>Index</th>
                        <!-- <th>Service Vote</th> -->
                        <th>Customer NIC</th>
                        <th>Customer Name</th>
                        <th>Address</th>
                        <th>Contact No</th>
                        <th>Request Date</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <!-- <th>Vote</th> -->
                        <th>Service Area</th>
                        <!-- <th>Feedback</th> -->
                        <!-- <th>Action</th> -->
                        <th>Stage</th>
                    </tr>
                </thead>
                <tbody>
                   
                    <tr v-for="(request, outerIndex) in innerArray" :key="outerIndex">
          <!-- Loop over each request within the current array -->
          <td >{{  (currentPage - 1) * 10 + outerIndex + 1 }}</td>
          <!-- <td >{{ request.vote }}</td> -->
          <td >{{ request.user_nic }}</td>
          <td >{{ request.name }}</td>
          <td >{{ request.address }}</td>
          <td >{{ request.mobile }}</td>
          <td >{{ new Date(request.req_date_from).toLocaleDateString() }}</td>
          <td >{{ request.amount }}</td>
          <td >{{ request.payment_status }}</td>
          <!-- <td >{{ request.vote }}</td> -->
          <td >{{ request.service_area }}</td>
          <!-- Display stage -->
          <td >
            <div v-if="request.payment_status === 'waiting'">
              <button @click="updateProcess(request.ob_id,request.service_type)" class="button-new" style="background-color:cornflowerblue;"><b>Process</b></button>
            </div>
            <div v-else-if="request.payment_status === 'processing'" style="background-color: rgba(255, 247, 22, 0.881);" @click="updatePayment(request.ob_id)" class="button-new">Update</div>
            <div v-else-if="request.payment_status === 'canceled'" style="background-color:coral"><b>canceled Request</b></div>
            <div v-else-if="request.payment_status === 'payment'" style="background-color: #12e447;" @click="updatePaid(request.ob_id)"><b>Notified for payment</b></div>
            <div v-else-if="request.payment_status === 'paid'" style="background-color: #43f00edf;" class="button-new"><b>{{ request.payment_status }}</b></div>
          </td>
        </tr>
                </tbody>
            </table>
            <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
            <br>
            </div>
            
        </div>
     <!-- popup  -->
     <div class="modal" v-if="showPopup">
      <div class="modal-content" ref="modalRef">
        <button class="close-x" @click="closePopup"> X</button>
        <div class="modal-header">
          <h2>Action for Request</h2>
        </div>
        <div class="modal-body">
          <div >
                   <label for="check">Option
                   </label>
                 <select v-model="objct.iaction" id="check" class="form-control" >
                       <option  selected disabled>
                                Select
                       </option>
                       <option value="pay">Add Pay amount for request</option>
                       <option value="rej">Reject Request</option>

                   </select>

                   <p class="error-mess" v-if="errorObject.actionError.length > 0">{{ errorObject.actionError[0] }}</p> 
               </div><br>
                <div v-if="objct.iaction === 'pay'" >
                    <!-- Your content for 'Add Pay amount for request' goes here -->
                    <label for="amount">Pay Amount
                   </label>
                   <input type="text" name="amount"  id="amount" class="form-control"
                    v-model="objct.amount" />
                    
                </div>

                <!-- Div section to show when 'Reject Request' is selected -->
                <div v-if="objct.iaction === 'rej'" >
                    <!-- Your content for 'Reject Request' goes here -->
                    <label for="reason">Reason for rejecting the request
                   </label>
                   <textarea name="reason" id="reason" class="form-control" v-model="objct.reason"></textarea>
                </div>
        </div>

        <div class="modal-footer">

          <button class="close-button" @click="closePopup">Close</button>
          <button class="save-button" @click="saveModal">Save</button>
        </div>

      </div>
    </div>
    <!-- end popup -->

  </template>
  <script>
  import axios from "axios";
  import { mapState, mapMutations } from "vuex";
  export default {

name: "Bkgully",

data() {
    return {
        subjects: [],
        allRequest: [],
        sabhadetail:[],
        objct:{ idate:"",iaction:"", amount:"", reason:""},
        itemsPerPage: 15,
        currentPage: 1,
        Sabhadata:[],
       temp:[],
       showPopup: false,
       errorObject:{actionError:[]},
       searchReqID:'',
    }
},
computed: {
        ...mapState(["user"]),
        paginatedData() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    
    // Check if this.data is an array before using slice method
    if (Array.isArray(this.allRequest)) {
      return this.allRequest.slice(startIndex, endIndex);
    } else {
      // Return empty array or handle loading state
      return [];
    }
  },
  totalPages() {
      return Math.ceil(this.allRequest.length / this.itemsPerPage);
    },

    },
   

created() {
    this.getDataFromSessionStorage()
    // this.getallRequest()
},

methods: {
    ...mapMutations(["setUser"]),

    async getDataFromSessionStorage(){
            const data = JSON.parse(sessionStorage.getItem('userData'))
            if(data){
                            this.nic = data.nic 
                            this.sabha = data.sabha
                            this.userLevel =data.userLevel
                        }
        // this.sabhaheads = (await axios.get('/headsbysabha/'+this.sabha)).data;
        //get subjects of employee
        this.Sabhadata = await axios.get('/onlserviceheads/'+ this.nic +"/"+ this.sabha)
        this.subjects = this.Sabhadata.data
    //load all requests
    //this.allRequest= (await axios.get('/allgully/'+this.user.emp_prs_code+"/"+'gully')).data;
    
    for(let i=0; i<this.subjects.length; i++){
   
        this.temp=(await axios.get('/allgully/'+this.user.emp_prs_code+"/"+this.subjects[i].subjecttype)).data;
        if(this.temp.length>0){
        this.allRequest.push(this.temp)
        }
        // this.allRequest= (await axios.get('/allgully/'+this.user.emp_prs_code+"/"+'gully')).data;
    }

        
        },


    nextPage(event) {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
        }
        // Prevent default form submission behavior
        event.preventDefault();
        },
        prevPage(event) {
        if (this.currentPage > 1) {
            this.currentPage--;
        }
        // Prevent default form submission behavior
        event.preventDefault();
        },
        async updateProcess(gid,stype){
            let bookingdata =(await axios.get('/onbookingrequestgid/'+gid)).data;
           
            const bdata =
                            {   req_name : bookingdata[0].name,
                                req_address: bookingdata[0].address,
                                tel:bookingdata[0].mobile,
                                reqid:bookingdata[0].ob_id ,
                                req_reason:bookingdata[0].req_reason,
                                req_date_from:bookingdata[0].req_date_from,
                                req_date_to:bookingdata[0].req_date_to,
                                req_start_time:bookingdata[0].req_start_time,
                                req_end_time:bookingdata[0].req_end_time,
                                req_seats:bookingdata[0].req_seats,
                                req_guests:bookingdata[0].req_guests,
                                req_get_payments:bookingdata[0].req_get_payments
                            } 
                            window.sessionStorage.setItem('bookingData', JSON.stringify(bdata))
            
                            if(stype=='gully'){
                                this.$router.push('/appgully');
                            }
                            if(stype=='stadiums'){
                                this.$router.push('/appground');
                            }
                            if(stype=='townhall'){
                                this.$router.push('/apptown');
                            }
                            if(stype=='crematoria'){
                                this.$router.push('/appcre');
                            }
                           
            //push to different services types
            
            ///appgully
            // let updateprocess={
            //     payment_status : "processing"
            // }
            // await axios.put("/gullyrequestupdate/" +gid , updateprocess, )
           
  
        },
        async updateCancel(gid){
            let updateprocess={
                payment_status : "canceled"
            }
            await axios.put("/gullyrequestupdate/" +gid , updateprocess, )

        },
        async updatePayment(gid){
            // let updateprocess={
            //     payment_status : "payment"
            // }
            // await axios.put("/gullyrequestupdate/" +gid , updateprocess, )
            this.searchReqID = gid
            this.showPopup = true;
            document.addEventListener('mouseup', this.closeModalOnClickOutside);
            
        },
        async updatePaid(gid){
            let updateprocess={
                payment_status : "Payment"
            }
            await axios.put("/gullyrequestupdate/" +gid , updateprocess, )
   
        },
        async saveModal(e) {
            // this.searchReqID = id
            this.checkForm();
            if (!this.checkEmptyErr()) {
                e.preventDefault();
                this.$refs.alert.showAlert('error', 'Something Went Wrong')
            }else{
                if(this.objct.iaction === 'pay'){
                    let amountdata={
                    amount:this.objct.amount,
                    payment_status : "payment"
                }
                await axios.put("/updamount/" +this.searchReqID , amountdata, );
                alert('Successfully Saved data')
                this.closePopup();
                }
                else if(this.objct.iaction === 'rej'){
                    let feeddata={
                        feedback:this.objct.reason,
                        payment_status :"canceled"
                    }
                    await axios.put("/updfeedb/" +this.searchReqID , feeddata, );
                    alert('Successfully Saved data')
                    this.closePopup();
                }
                this.allRequest=[]
                this.getDataFromSessionStorage()
                // for(let i=0; i<this.subjects.length; i++){
   
                // this.temp=(await axios.get('/allgully/'+this.user.emp_prs_code+"/"+this.subjects[i].subjecttype)).data;
                // if(this.temp.length>0){
                // this.allRequest.push(this.temp)
                // }
                // // this.allRequest= (await axios.get('/allgully/'+this.user.emp_prs_code+"/"+'gully')).data;
                // }
        }
           
        },
        openPopup() {
            this.showPopup = true;
            document.addEventListener('mouseup', this.closeModalOnClickOutside);
        },
        closePopup() {
            this.showPopup = false;
            document.removeEventListener('mouseup', this.closeModalOnClickOutside);
        },
        closeModalOnClickOutside(event) {
            const modal = this.$refs.modalRef;
            if (!modal.contains(event.target)) {
                this.closePopup();
            }
        },
        resetCheckErr: function () {
           this.errorObject.actionError = [];
           

       },
       checkEmptyErr: function () {
           for (var typeErr in this.errorObject) {
               if (this.errorObject[typeErr].length != 0) {
                   return false;
               }
           }
           return true;
       },
       checkForm: function () {
           this.resetCheckErr();
           
           if (!this.objct.iaction) {
            this.errorObject.actionError.push("selecting action is required"); 
           }
          
   },
}

}
 
</script>
<style scoped>

.form-control{
    width: 60%;
    font-size: 14px;
   
}
.modal {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9999;
  }

  .modal-content {
      background-color: white;
      /* padding: 5px; */
      border-radius: 1px;
      /* max-width: 500px; */
      width: 40%;
      position: relative;
  }

  .close x {
      position: absolute;
      top: 5px;
      right: 10px;
      padding: 5px;
      background-color: transparent;
      border: 1rem;
      cursor: pointer;
      font-size: 18px;
      color: #555;
  }

  .close-x:hover {
      color: #ff0000;
      /* Change to your desired hover color */
  }


  .modal-header {
      /* padding-bottom: 2px; */
      border-bottom: 1px solid #ccc;
  }

  .modal-body {
      /* padding: 10px 20px 20px 20px; */
      padding-left: 5%; 
      font-size: 14px;

  }
  .modal-footer {
      padding-top: 5px;
      border-top: 1px solid #ccc;
      display: flex;
      justify-content: flex-end;
  }

  .close-button,
  .save-button {
      padding: 5px 10px;
      border: none;
      cursor: pointer;
      font-weight: bold;
      border-radius: 4px;
      transition: background-color 0.3s ease;
  }

  .close-button {
      background-color: #f44336;
      color: white;
  }

  .save-button {
      background-color: #4CAF50;
      color: white;
      margin-left: 5px;
  }

  .close-button:hover {
      background-color: #d32f2f;
  }

  .save-button:hover {
      background-color: #45a049;
  }
  .rowpop{
    padding-left: 30%;
  }
.button-new {
  display: inline-block;
  padding: 10px 20px; /* Adjust padding as needed */
  font-size: 12px; /* Adjust font size as needed */
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #ccc; /* Add a border for a button-like appearance */
  border-radius: 5px; /* Optional: Add rounded corners */
  background-color: #ebedf0; /* Set a background color */
  color: #423f3f; /* Set text color */
  width: 10rem;
}
.button-link {
  display: inline-block;
  padding: 10px 20px; /* Adjust padding as needed */
  font-size: 12px; /* Adjust font size as needed */
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #ccc; /* Add a border for a button-like appearance */
  border-radius: 5px; /* Optional: Add rounded corners */
  background-color: #007bff; /* Set a background color */
  color: #fff; /* Set text color */
}

.button-link:hover {
  background-color: #0056b3; /* Change background color on hover */
}
.ttable {
     
     width: 100%;
     border-width:2px;
     border-color : #400f3c;
     /* border-style: solid; */
     font-size: 1.2rem;
     /* background-color :white; */
     /* padding-left: 3rem; */
      /* height: 3rem; */
 }
 th  {
     text-align: center;
}

.ttable, th, td {
border: 1px solid;
}

 
 .table-responsive {
     margin-top: 8vh;
     height: 500px;
 }
.admin-container {
    background-color: #fff;
    /* height: 100vh; */
    padding: 2rem 9%;
    font-size: 16px;
     min-height: 72.3vh;
}
/* #4e0707; */
.admin-container span {
    color: #4e0707;
    font-size: larger;
}
.project-list>tbody>tr>td {
    padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
    width: 22px;
    border: 1px solid #CCC;
}

.table-responsive {
    margin-top: 8vh;
}

.action-btn,
.cancel-btn,
.paid-btn {
    width: 100px;
    height: 25px;
    color: white;
    text-transform: capitalize;
}

.action-btn {
    background-color: #0da9ef;
    margin-right: 10px;
}

.cancel-btn,
.paid-btn {
    background-color: red;
}

.action-btn:hover {
    background-color: #27ae60;
}
</style>